<template>
  <div>
    <!--Mainmenu-area-->
    <div class="mainmenu-area affix" data-spy="affix" data-offset-top="50">
      <div class="container">
        <!--Logo-->
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#primary-menu">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <button type="button" class="navbar-toggle mostrarbtn" style="margin-right: 1px !important">
            <a href="https://app.blito.ar " class="" style="padding: 2px !important">
              <span class="glyphicon glyphicon-th" style="font-size: 20px; top: -3px">
              </span>
            </a>
          </button>
          <a href="https://blito.ar/" class="navbar-brand" style="padding: 25px 15px">
            <h2>blito.ar</h2>
          </a>
        </div>
        <!--Logo/-->
        <nav class="collapse navbar-collapse" id="primary-menu">
          <ul class="nav navbar-nav navbar-right">
            <li class="active"><a href="#home-page">Home</a></li>
            <li><a href="#service-page">Servicio</a></li>
            <li><a href="#product-page">Productos</a></li>
            <!-- <li><a href="#price-page">Pricing</a></li> -->
            <!-- <li><a href="#team-page">Equipo</a></li> -->
            <!-- <li><a href="#faq-page">FAQ</a></li> -->
            <!-- <li><a href="#blog-page">Blog</a></li> -->
            <li><a href="#contact-page">Contacto</a></li>
            <div style="float: left; display: block; padding: 25px 15px">
              <router-link to="/Tienda"><span class="btn btn-success" style="
                    border-radius: 24px;
                    margin: auto;
                    padding-left: 20px;
                    padding-right: 20px;
                  ">
                  Tiendas
                </span></router-link>
            </div>
            <div style="float: left; display: block; padding: 25px 15px">
              <a href="https://app.blito.ar ">
                <span class="btn btn-primary" style="
                    border-radius: 24px;
                    margin: auto;
                    padding-left: 20px;
                    padding-right: 20px;
                  ">
                  Mi app
                </span>
              </a>
            </div>
          </ul>
        </nav>
      </div>
    </div>
    <!--Mainmenu-area/-->

    <!--Header-area-->
    <header class="header-area overlay full-height relative v-center" id="home-page">
      <div class="absolute anlge-bg"></div>
      <div class="container">
        <div class="row v-center">
          <div class="col-xs-12 col-md-7 header-text">
            <h2>Hay decisiones que cambian tu empresa.</h2>
            <p>
              En blito.ar desarrollamos sistemas para empresas que apuestan a la
              tecnología y a la innovación para hacer crecer sus negocios.
            </p>
            <!-- <a href="#" class="button white">Watch video</a> -->
          </div>
        </div>
      </div>
    </header>
    <!--Header-area/-->

    <!--service-page-area-->
    <section class="gray-bg section-padding" id="service-page">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-4">
            <div class="box">
              <div class="box-icon">
                <img src="images/portfolio-icon-5.png" alt="" />
              </div>
              <h4>Desarrollo de aplicativos moviles</h4>
              <p></p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4">
            <div class="box">
              <div class="box-icon">
                <img src="images/service-icon-2.png" alt="" />
              </div>
              <h4>Desarrollo de paginas web</h4>
              <p></p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4">
            <div class="box">
              <div class="box-icon">
                <img src="images/service-icon-3.png" alt="" />
              </div>
              <h4>Inteligencia de negocio</h4>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Feature-area/-->
    <section class="angle-bg sky-bg section-padding" id="product-page">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div id="caption_slide" class="carousel slide caption-slider" data-ride="carousel">
              <div class="carousel-inner" role="listbox">
                <div class="item active row">
                  <div class="v-center">
                    <div class="col-xs-12 col-md-6">
                      <div class="caption-title" data-animation="animated fadeInUp">
                        <h2>App reparto</h2>
                      </div>
                      <div class="caption-desc" data-animation="animated fadeInUp">
                        <p>
                          Aplicación movíl que pemite la administracion de
                          repartos.
                        </p>
                        <p>Realtime para estados de clientes y repartos.</p>
                      </div>
                      <!--  <div class="caption-button" data-animation="animated fadeInUp">
                                            <a href="#" class="button">Read more</a>
                                        </div> -->
                    </div>
                    <div class="col-xs-6 col-md-3">
                      <div class="caption-photo one" data-animation="animated fadeInRight">
                        <img src="images/app/reparto/splashscreen.png" alt="" />
                      </div>
                    </div>
                    <div class="col-xs-6 col-md-3">
                      <div class="caption-photo two" data-animation="animated fadeInRight">
                        <img src="images/app/reparto/Home.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item row">
                  <div class="v-center">
                    <div class="col-xs-12 col-md-6">
                      <div class="caption-title" data-animation="animated fadeInUp">
                        <h2>Gestiona rutas y clientes</h2>
                      </div>
                      <div class="caption-desc" data-animation="animated fadeInUp">
                        <p>
                          Organizá y administra la informacion, estado de tus
                          rutas y clientes.
                        </p>
                      </div>
                      <!-- <div class="caption-button" data-animation="animated fadeInUp">
                                            <a href="#" class="button">Read more</a>
                                        </div> -->
                    </div>
                    <div class="col-xs-6 col-md-3">
                      <div class="caption-photo one" data-animation="animated fadeInRight">
                        <img src="images/app/reparto/Ruta.png" alt="" />
                      </div>
                    </div>
                    <div class="col-xs-6 col-md-3">
                      <div class="caption-photo two" data-animation="animated fadeInRight">
                        <img src="images/app/reparto/Clientes.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item row" style="margin-bottom: 56px">
                  <div class="v-center">
                    <div class="col-xs-12 col-md-5">
                      <div class="caption-title" data-animation="animated fadeInUp">
                        <h2>Gestion web</h2>
                      </div>
                      <div class="caption-desc" data-animation="animated fadeInUp">
                        <p>
                          Aplicación web para al administracion clientes,
                          repartos, comprobantes y todo aquello necesario para
                          tu negocio
                        </p>
                      </div>
                      <!--  <div class="caption-button" data-animation="animated fadeInUp">
                                            <a href="#" class="button">Read more</a>
                                        </div> -->
                    </div>
                    <div class="col-xs-12 col-md-7">
                      <div class="col-xs-12 col-md-12">
                        <div class="caption-photo one" data-animation="animated fadeInRight"
                          style="box-shadow: none !important">
                          <img src="images/app/reparto/webReparto.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item row">
                  <div class="v-center">
                    <div class="col-xs-12 col-md-6">
                      <div class="caption-title" data-animation="animated fadeInUp">
                        <h2>Agenda Digital</h2>
                      </div>
                      <div class="caption-desc" data-animation="animated fadeInUp">
                        <p>Organiza los turnos de tus pacientes. <br /> Podras notificar de forma masiva a traves de
                          mensaje de texto turnos agendados y posibles cancelaciones.</p>
                      </div>
                    </div>
                    <div class="col-xs-6 col-md-3">
                      <div class="caption-photo one" data-animation="animated fadeInRight">
                        <img src="images/app/agenda/screen_1.png" alt="">
                      </div>
                    </div>
                    <div class="col-xs-6 col-md-3">
                      <div class="caption-photo two" data-animation="animated fadeInRight">
                        <img src="images/app/agenda/screen_2.png" alt="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Indicators -->
              <ol class="carousel-indicators caption-indector" style="overflow: auto">
                <li data-target="#caption_slide" data-slide-to="0" class="active">
                  <strong>App para repartos </strong>
                </li>
                <li data-target="#caption_slide" data-slide-to="1">
                  <strong>Rutas y clientes </strong>
                </li>
                <li data-target="#caption_slide" data-slide-to="2">
                  <strong>Gestion web reparto</strong>
                </li>
                <li data-target="#caption_slide" data-slide-to="3">
                  <strong>Agenda Digital</strong>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- contact-page -->
    <footer class="footer-area relative sky-bg" id="contact-page">
      <div class="absolute footer-bg"></div>
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-7 col-sm-offset-3 text-center">
              <div class="page-title">
                <h2>Comuníquese con nosotros</h2>
                <p>Ponemos a tu disposición nuestro canales de comunicación</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-12">
              <address class="side-icon-boxes">
                <div class="col-xs-12 col-md-4">
                  <div class="side-icon-box">
                    <div class="side-icon">
                      <img src="images/location-arrow.png" alt="" />
                    </div>
                    <p><strong>Dirección: </strong>Cordoba <br />Argentina</p>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4">
                  <div class="side-icon-box">
                    <div class="side-icon">
                      <img src="images/phone-arrow.png" alt="" />
                    </div>
                    <p>
                      <strong>Telefono: </strong>
                      <a href="callto:+5493516111900">+54 9 (351) 611 1900</a>
                      <br />
                    </p>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4">
                  <div class="side-icon-box">
                    <div class="side-icon">
                      <img src="images/mail-arrow.png" alt="" />
                    </div>
                    <p>
                      <strong>E-mail: </strong>
                      <a href="mailto:contacto@blito.ar">contacto@blito.ar</a>
                      <br />
                    </p>
                  </div>
                </div>
              </address>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-middle">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-6 pull-right">
              <ul class="social-menu text-right x-left">
                <li>
                  <a href="https://www.instagram.com/blito.dev/"><i class="ti-instagram"></i></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/pjleiva"><i class="ti-linkedin"></i></a>
                </li>
                <li>
                  <a href="https://gitlab.com/pleivasoft"><i class="ti-github"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 text-center hidden">
              <p>
                ©Copyright 2015 All right resurved.
                <a href="https://colorlib.com">Colorlib</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
    
<script>
export default {
  name: "HomePage",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
