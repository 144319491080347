import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Tienda from "../views/Tienda.vue";

import MiddlewareStylesheet from './middleware/stylesheet'

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            stylesheet: "Home"
        }
    },
    {
        path: "/tienda",
        name: "Tienda",
        component: Tienda,
        meta: {
            stylesheet: "Tienda"
        }
    },
    {
        path: "/qr",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/About.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach(MiddlewareStylesheet);

export default router;