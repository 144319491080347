<template>
  <div class="container theme-showcase" role="main">
    <div class="jumbotron img-banner"></div>
    <div class="row col-md-12 m-t-n-lg">
      <div class="css-1cnv4ys">
        <div class="css-avatar"></div>
        <div class="css-u5y05n m-l-lg">
          <h1 class="m-t-n-xxs"><strong>blito.ar</strong></h1>
          <h4><p>Construimos software para tu empresa.</p></h4>
        </div>
        <div class="css-lp74t4">
          <button class="btn btn-danger btn-circle btn-lg" type="button">
            <i class="ti-instagram"></i>
          </button>

          <button class="btn btn-success btn-circle btn-lg" type="button">
            <i class="ti-facebook"></i>
          </button>
          <button class="btn btn-primary btn-circle btn-lg" type="button">
            <svg
              height="100%"
              style="fill: white"
              viewBox="0 0 24 24"
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="cls-1"
                d="M20.5 3.49a12 12 0 00-20.4 8.4 11.82 11.82 0 001.6 5.95L0 24l6.33-1.65A12 12 0 0012 23.79 11.94 11.94 0 0024 11.9a11.8 11.8 0 00-3.5-8.41zm-8.45 18.3A10 10 0 017 20.41l-.36-.21-3.76 1 1-3.65-.24-.37A9.88 9.88 0 0112.05 2a9.89 9.89 0 110 19.78zm5.45-7.4c-.3-.15-1.77-.87-2-1s-.47-.15-.67.15-.77 1-.95 1.17-.35.22-.65.07a8.17 8.17 0 01-2.4-1.47 9 9 0 01-1.66-2.06c-.17-.3 0-.46.13-.61s.3-.35.45-.52a2 2 0 00.3-.5.55.55 0 000-.52c-.14-.1-.74-1.58-.99-2.18s-.49-.5-.67-.51h-.57a1.1 1.1 0 00-.8.37A3.33 3.33 0 006 9.25a5.78 5.78 0 001.2 3.07 13.27 13.27 0 005.1 4.49 17.31 17.31 0 001.7.63 4.11 4.11 0 001.88.12 3.07 3.07 0 002-1.41 2.48 2.48 0 00.17-1.41c-.05-.13-.25-.21-.55-.36z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="input-group">
          <input type="text" class="form-control" aria-label="..." />
          <div class="input-group-btn">
            <button
              type="button"
              class="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Todas las categorias<span class="caret"></span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li><a href="#">Agua</a></li>
              <li><a href="#">soda</a></li>
              <li><a href="#">Insumos</a></li>
            </ul>
          </div>
          <!-- /btn-group -->
        </div>
        <!-- /input-group -->
      </div>
    </div>

    <div class="row">
      <div class="wrapper wrapper-content animated fadeInRight">
        <!-- <div v-for="(row, index) in chunkedOrigin" :key="index" class="row"> -->
          <div
            class="col-xs-6 col-md-4"
            v-for="(item, index2) in productos"
            :key="index2"
          >
            <div class="ibox">
              <div class="ibox-content product-box">
                <div class=" css-image">
                  <!-- <img class="" style="width: 200px" src="images/logo.png" /> -->
                </div>
                <div class="product-desc">
                  <span class="product-price text-"> $ {{ item.precio }} </span>
                  <small class="text-muted">{{ item.categoria }}</small>
                  <a href="#" class="product-name"> {{ item.nombre }}</a>
                  <!-- <div class="small m-t-xs">
                    {{ item.descripcion }}
                  </div> -->
                  <div class="row m-t-sm">
                   <!--  <div class="col-xs-12 col-sm-6">
                      <a href="#" class="btn btn-sm btn-outline btn-primary"
                        >Mas info <i class="ti-arrow-right"></i>
                      </a>
                    </div> -->
                    <div class="col-xs-12 " >
                      <a
                        class="btn btn-sm btn-success"
                        v-on:click="item.cantidad = 1"
                        v-if="item.cantidad == 0"
                        >Agregar a carrito<i class="fa fa-long-arrow-right"></i>
                      </a>
                      <div class="input-group" v-if="item.cantidad > 0">
                        <span class="input-group-btn">
                          <button
                            class="btn btn-sm  btn-success"
                            type="button"
                            v-on:click="item.cantidad -= 1"
                          >
                            <strong style="padding: 20px">-</strong>
                          </button>
                        </span>
                        <input
                          type="text"
                          class="form-control text-center input-sm"
                          v-model="item.cantidad"
                        />
                        <span class="input-group-btn">
                          <button
                            class="btn btn-sm btn-success"
                            type="button"
                            v-on:click="item.cantidad += 1"
                          >
                            <strong style="padding: 20px">+</strong>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
    <div
      class="row animated fadeInUp"
      style="position: fixed; bottom: 0; left: 0; width: 100%"
      v-if="this.cantidadDeArticulos > 0"
    >
      <div class="alert alert-info" style="width: 340px; margin: auto">
        <a class="alert-link" href="#"> [ {{ cantidadDeArticulos }} ] </a>
        articulos seleccionados.
        <strong class="pull-right">$ {{ this.totalCarrito }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tienda",
  data: () => {
    return {
      cantidadDeArticulos: 0,
      totalCarrito: 0,
      carrito: [],
      productos: [
        {
          articuloId: 1015,
          nombre: "Soda",
          precio: 30.0,
          retornable: true,
          activo: true,
          categoria: "Soda",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1016,
          nombre: "Agua de 10 Litros",
          precio: 130.0,
          retornable: true,
          activo: true,
          categoria: "Agua",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1017,
          nombre: "Agua de 20 Litros",
          precio: 200.0,
          retornable: true,
          activo: true,
          categoria: "Agua",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1018,
          nombre: "Agua de 25 Litros",
          precio: 260.0,
          retornable: true,
          activo: true,
          categoria: "Agua",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1019,
          nombre: "Envase de soda",
          precio: 300.0,
          retornable: false,
          activo: true,
          categoria: "Insumos",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1020,
          nombre: "Envase de 10 Litros",
          precio: 450.0,
          retornable: false,
          activo: true,
          categoria: "Insumos",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1021,
          nombre: "Envase de 20 Litros",
          precio: 600.0,
          retornable: false,
          activo: true,
          categoria: "Insumos",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1022,
          nombre: "Envase de 25 Litros",
          precio: 650.0,
          retornable: false,
          activo: true,
          categoria: "Insumos",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1024,
          nombre: "Canasta",
          precio: 550.0,
          retornable: false,
          activo: true,
          categoria: "Insumos",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1025,
          nombre: "Esqueleto",
          precio: 900.0,
          retornable: false,
          activo: true,
          categoria: "Insumos",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1026,
          nombre: "Pie p/bidón",
          precio: 1000.0,
          retornable: false,
          activo: true,
          categoria: "Insumos",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
        {
          articuloId: 1029,
          nombre: "Soda B",
          precio: 25.0,
          retornable: true,
          activo: true,
          categoria: "Sodas",
          descripcion: "Descripcion del producto, calidad, precio, beneficio",
          cantidad: 0,
        },
      ],
    };
  },
  components: {},
  computed: {
    chunkedOrigin: function () {
      return this.productos.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / 3);

        if (!result[chunkIndex]) {
          result[chunkIndex] = []; // start a new chunk
        }
        result[chunkIndex].push(item);

        return result;
      }, []);
    },
  },
  methods: {
    handleChange(articulo) {
      // Handle changes here!
      this.cantidadDeArticulos = 0;
      this.totalCarrito = 0;
      if (articulo.cantidad == 0) {
        delete this.carrito[articulo.articuloId];
      } else {
        this.carrito[articulo.articuloId] = articulo;
      }
      let cantArticulos = 0;
      this.carrito.forEach((item) => {
        this.cantidadDeArticulos += item.cantidad;
        this.totalCarrito += item.cantidad * item.precio;
      });

      console.log(this.carrito);
    },
  },
  mounted() {},
  created() {
    this.productos.forEach((val) => {
      this.$watch(() => val, this.handleChange, { deep: true });
    });
  },
  watch: {
    /*  productos: {
      handler: function (after, before) {
        // Return the object that changed
         var vm = this;
        console.log(vm, after,before);
        let changed = after.filter( function( p, idx ) {
          return Object.keys(p).some( function( prop ) {
            return p[prop] !== vm.$data.oldPeople[idx][prop];
          })
        })
        // Log it
        vm.setValue();
        console.log(changed) 
      },
      deep: true,
    } */
  },
};
</script>

<style>

.css-image {
    background-color: rgb(237, 242, 247);
    background-image: url("/images/logo_1080_blito.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 0px;
    border-radius: 0.25rem;
    flex-shrink: 0;
    height: 24rem;
    opacity: 1;
    transition: opacity 500ms ease-in 0s;
    width: 100%;
    object-fit: contain;
}

.btn-circle.btn-lg {
  width: 40px;
  height: 40px;
  padding: 10px 10px;
  border-radius: 50px;
  font-size: 18px;
  line-height: 1.33;
  margin-left: 10px;
}

.css-n09zkg {
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
  outline: none;
  margin-right: 0.5rem;
}
.css-1cnv4ys {
  display: grid;
  grid-template-areas:
    "avatar links"
    "information information";
  grid-template-columns: auto;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .css-1cnv4ys {
    grid-template-areas: "avatar information links";
    grid-template-columns: auto 1fr auto;
  }
}

.css-avatar {
  background-color: rgb(237, 242, 247);
  background-image: url("/images/logo_1080_blito.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0px;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 2px inset, white 0px 0px 0px 5px;
  flex-shrink: 0;
  grid-area: avatar;
  height: 10rem;
  margin-right: 0px;
  margin-left: 2rem;
  margin-top: -3rem;
  min-height: 6rem;
  min-width: 6rem;
  opacity: 1;
  transition: opacity 500ms ease-in 0s;
  width: 10rem;
}
.css-u5y05n {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-area: information;
  margin-top: 0.25rem;
}
.css-lp74t4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 2.5rem;
  grid-area: links;
  margin-top: 1rem;
}

.img-banner {
  background-color: #e9e7e8;
  background-image: url("/images/parallax-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important; 
  height: 12rem;
  min-height: 6rem;
  width: 100%;
  /*margin-top: -50px;*/
}

@media (max-width: 480px) {
  .col-xs-6{
    padding-right: 5px;
    padding-left: 5px;
  }
}

/*
 @media (min-width: 575px) and (max-width: 1080px) {
  .img-circle {
    width: 50px !important;
  }
}


 */
/* @media (min-width: 320px) and (max-width: 575px) {
  .jumbotron {
    padding: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;

  }

 }*/
/* E-commerce */
body {
  background: #f7f5f5;
}
</style>
